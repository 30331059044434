<template>
  <div>
    <b-card-body align="left" class="trending__list px-1">
      <b-card-text>
        <div v-for="(article, index) in trending" :key="article._id">
          <p class="trending__list-item">
            <b-icon-star-fill
              v-if="index === 0"
              variant="warning"
              font-scale="1"
            >
            </b-icon-star-fill>
            <b-icon-star-half
              v-else-if="index === 1"
              variant="warning"
              font-scale="1"
            >
            </b-icon-star-half>
            <b-icon-star v-else variant="danger" font-scale="1"> </b-icon-star>
            <b-badge variant="primary">{{ index + 1 }}</b-badge>
            <router-link
              :to="{
                name: 'FullPost',
                params: { id: article._id },
              }"
              ><span class="trending__list-item__title">
                {{ article.title }}
              </span></router-link
            >
            <span class="trending__list-item__author"
              >- {{ article.author.name }}</span
            >
          </p>
          <hr class="my-2" />
        </div>
      </b-card-text>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardBody,
  BCardText,
  BBadge,
  BIconStar,
  BIconStarHalf,
  BIconStarFill,
} from "bootstrap-vue";

export default {
  components: {
    BCardBody,
    BCardText,
    BBadge,
    BIconStar,
    BIconStarHalf,
    BIconStarFill,
  },

  props: {
    trending: {},
  },

  methods: {
    getIcon(index) {
      if (index === 0) {
        return "star-fill";
      } else if (index === 1) {
        return "star-half";
      } else {
        return "star";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.trending {
  &__list {
    max-height: 47vh;
    overflow-y: auto;
  }
  p {
    margin-bottom: 0;
  }
  &__list-item {
    font-size: 0.9rem;

    &__title {
      color: black;
      &:hover {
        font-weight: bold;
      }
    }

    &__author {
      font-size: 0.8rem;
      font-style: italic;
    }
  }
}
</style>
